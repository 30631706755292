import styled from 'styled-components';
import { fontBold, fontMedium } from '../../assets/styles/typography';
import { colors } from '../colors';

function cursorControl(cursor) {
	if (cursor === 'grab')
		return `&:hover {
			cursor: grab;
			cursor: -webkit-grab;
		}`;
	if (cursor === 'copy')
		return `&:hover {
			cursor: copy;
		}`;
	if (cursor === 'default')
		return `&:hover {
			cursor: default;
		}`;
	return null;
}

const Button = styled.button`
	color: ${colors.White};
	transition: ease-in all 0.4s;
	height: 52px;
	font-size: 18px;
	padding: 0.25em 25px;
	border-radius: 4px;
	text-transform: uppercase;
	display: flex;
	align-items: center;
	justify-content: center;
	border: 1px solid transparent;
	white-space: nowrap;
	transition: ease-in all 0.4s;
	width: ${(props) => (props.block ? '100%' : 'auto')};
	${({ navigationLeftButton }) =>
		navigationLeftButton &&
		`border: 1px solid #3263D6;
	border-radius: 50px !important;
padding: -43px;
	margin-right: 15px;
	min-width:20px !important;
	min-height:20px !important;
	width:20px !important;
	height:20px !important;
	:hover{
			background: ${colors.BlueLight};
			border:1px solid ${colors.BlueLigBaht};
			color: ${colors.White}!important;
		}`}

	${({ navigationRightButton }) =>
		navigationRightButton &&
		`border: 1px solid #3263D6;
	border-radius: 50px !important;
padding: -43px;
	margin-left: 15px;
	min-width:20px !important;
	min-height:20px !important;
	width:20px !important;
	height:20px !important;
	:hover{
			background: ${colors.BlueLight};
			border:1px solid ${colors.BlueLight};
			color: ${colors.White}!important;
		}`}

	${({ disabled }) =>
		disabled &&
		`cursor: default;
		pointer-events: none;
		opacity: 0.5;
	`}
	${({ addVital }) =>
		addVital &&
		`
		width: auto !important;
		padding: 4px 10px;
		background: ${colors.BlueBase};
		border:1px solid ${colors.BlueBase};
		color: ${colors.White}!important;
		font-family: ${fontMedium.familyName};;
        margin-top: 8px;
        margin-right: 8px;
        //font-weight: 600;
        font-size: 12px;
        color:#57668F;
        display: inline-block;
		// padding: 6px 15px;
		height: auto;
		// font-size: 14px;
		text-transform: none;
		&:hover{
			background: ${colors.BlueLight};
			border:1px solid ${colors.BlueLight};
			color: ${colors.White}!important;
			span {
			color:${colors.White}!important;
		}
	}
		
		span{
			font-size: 11px;
			padding-right:6px;
		}
        span{
           color: ${colors.White}!important;
            margin-right: 2px;
        }
        @media screen and (max-width: 480px) {
			width: 100%;
		}
	`};
	${({ selected }) =>
		selected &&
		`
        color: ${colors.white} !important;
		background: #6F81A2 !important;

	`}
	${({ attachmentClose }) =>
		attachmentClose &&
		`padding:5px;
		border:none;
		background:${colors.White}!important;
		margin:0px 0 auto auto;
		height:24px;
		width:24px;
		flex:0 0 24px;
		&:hover{
			background: ${colors.BlueE6}!important;
			border:1px solid ${colors.BlueE6};
		}
	`}
	${({ btnResponsive }) =>
		btnResponsive &&
		`@media screen and (max-width: 550px) {
			width:100%;
		}
	`}
	:hover {
		cursor: pointer;
		outline: none;
	}
	:focus {
		outline: none;
	}
	margin: ${(props) => props.margin};
	margin-left: ${(props) => props.marginLeft};
	${({ attachmentRetry }) =>
		attachmentRetry &&
		`color:${colors.BlueBase}!important;
		background: transparent!important;
		width: 60px;
		flex: 0 0 60px;
		margin-right: 10px;
		align-items: baseline;
		padding-top: 1px;
		display: inline-block;
		height: auto;
		padding:0;
		img{
			margin-bottom: 0px;
			float: left;
			margin-top: 3px;
		}
		span{
			font-size: 14px;
			line-height: 16px;
			margin-left: 5px;
			margin-top: 3px;
			float: left;
			font-family: ${fontBold.familyName};
		}

	`}
	${({ attachmentClose }) =>
		attachmentClose &&
		`padding:5px;
		border:none;
		background:${colors.White}!important;
		margin:1px0 0 auto auto;
		height:24px;
		width:24px;
		flex:0 0 24px;
		&:hover{
			background: ${colors.BlueE6}!important;
			border:1px solid ${colors.BlueE6};
		}
	`}
	${({ primary }) =>
		primary &&
		`background: ${colors.BlueBase};
		border:1px solid ${colors.BlueBase};
		font-family: ${fontMedium.familyName};
		:hover{
			background: ${colors.BlueLight} !important;
			border:1px solid ${colors.BlueLight} !important;
		}
	`}
	${({ primaryBorder }) =>
		primaryBorder &&
		`background: ${colors.White};
		border:1px solid ${colors.BlueBase};
		color: ${colors.BlueBase}!important;
		font-family: ${fontMedium.familyName};
		:hover{
			background: ${colors.BlueLight};
			border:1px solid ${colors.BlueLight};
			color: ${colors.White}!important;
			span {
			color:${colors.White}!important;
		}
		}
		
	`}
	${({ primaryTextIcon }) =>
		primaryTextIcon &&
		`background: ${colors.White};
		border:0px;
		color: ${colors.BlueLight}!important;
		font-family: ${fontMedium.familyName};
		text-transform: none;
		font-size: 16px;
		height: 30px;
		:hover{
			background: ${colors.White};
			border:0px;
			color: ${colors.BlueBase}!important;
		}
		span{
			margin-right:8px;
			font-size: 18px;
		}
	`}

${({ dangerTextIcon }) =>
		dangerTextIcon &&
		`background: ${colors.White};
		border:0px;
		color: ${colors.Red1}!important;
		font-family: ${fontMedium.familyName};
		text-transform: none;
		font-size: 16px;
		height: 30px;
		:hover{
			background: ${colors.White};
			border:0px;
			color: ${colors.Red}!important;
		}
		span{
			margin-right:8px;
			font-size: 18px;
		}
	`}
	${({ mwButton }) =>
		mwButton &&
		`min-width: 140px;
	`}
	${({ secondary }) =>
		secondary &&
		`background: ${colors.White};
		border: 1px solid ${colors.BlueDark};
		color: ${colors.BlueDark};
		:hover{
			background: ${colors.BlueDark};
			color:${colors.White};
		}
	`}

${({ danger }) =>
		danger &&
		`background: ${colors.White};
		border: 1px solid ${colors.Red};
		color: ${colors.Red};
		:hover{
			background: ${colors.Red};
			color:${colors.White};
		}
	`}
	${({ success }) =>
		success &&
		`background: ${colors.GreenBase};
		border: 1px solid ${colors.GreenBase};
		color: ${colors.White};
		:hover{
			background: ${colors.GreenDark};
		}
	`}

	${({ blueButton }) =>
		blueButton &&
		`background: ${colors.BlueLight};
		border: 1px solid ${colors.BlueLight};
		color: ${colors.White};
		:hover{
			background: ${colors.BlueLightDark};
		}
	`}

	${({ iconButton }) =>
		iconButton &&
		`width:48px;
		height:48px;
		min-width:48px;
		min-height:48px;
		border-radius: 4px;
		font-size: 22px;
		padding:0px;
	`}
	${({ iconButtonSM }) =>
		iconButtonSM &&
		`width:30px;
		height:30px;
		min-width:30px;
		min-height:30px;
		border-radius: 0px;
		font-size: 18px;
		padding:0px;
		background: transparent;
	`}
	${({ iconButtonShared }) =>
		iconButtonShared &&
		`
		span{
			color: ${colors.GreenBase};
			font-size: 16px;
			transition: ease-in all 0.4s;
		}
		// &:hover{
		// 	span{
		// 		color: ${colors.GreyC4};
		// 	}
		// }
	`}
	${({ iconButtonNonShared }) =>
		iconButtonNonShared &&
		`
		span{
			color: ${colors.GreyC4};
			transition: ease-in all 0.4s;
			font-size: 16px;
		}
		&:hover{
			span{
				color: ${colors.GreenBase};
			}
		}
	`}
	${({ iconButtonSMTL }) =>
		iconButtonSMTL &&
		`width:30px;
		height:30px;
		min-width:30px;
		min-height:30px;
		border-radius: 0px;
		padding:0px;
		background: transparent;
	`}
	${({ iconPrimary }) =>
		iconPrimary &&
		`color: ${colors.BlueBase};
		&:hover{
			color: ${colors.BlueLight}
		}
	`}
	${({ iconSecondary }) =>
		iconSecondary &&
		`color: ${colors.Grey87};
		&:hover{
			color: ${colors.BlueBase}
		}
	`}

	${({ roundButton }) =>
		roundButton &&
		`border-radius:100%;
	`}
	${({ smallRoundButton }) =>
		smallRoundButton &&
		`border-radius:100%;
		font-size:16px;
		height:30px;
		padding:10px;
		margin-top:10px;
	`}

	${({ shadowButton }) =>
		shadowButton &&
		`box-shadow: 0px 4px 4px rgba(83, 109, 254, 0.25);
	`}
	${({ smallButton }) =>
		smallButton &&
		`padding: 6px 15px;
		height: auto;
		font-size: 14px;
		text-transform: none;
		span{
			font-size: 11px;
			padding-right:6px;
		}
	`}
	${({ smallButtonS }) =>
		smallButtonS &&
		`padding: 10px 15px;
		height: auto;
		font-size: 14px;
		text-transform: none;
		span{
			font-size: 11px;
			padding-right:6px;
		}
	`}
	${({ ButtonIconSmall }) =>
		ButtonIconSmall &&
		`font-size: 14px;
	`}
	${({ ButtonWhite }) =>
		ButtonWhite &&
		`padding: 6px 15px;
		height: auto;
		font-size: 14px;
		text-transform: none;
		color: rgba(15, 37, 95, 0.5);
		background: ${colors.White};
		+ button {
			margin-left:10px;
		}
		&:hover{
			background: #E1E5ED;
			color: ${colors.BlueBase};
			font-family: ${fontMedium.familyName};
		}
	`}
	${({ ButtonActive }) =>
		ButtonActive &&
		`background: #E1E5ED;
		color: ${colors.BlueBase};
		font-family: ${fontMedium.familyName};
	`}
	${({ ButtonHeightSmall }) =>
		ButtonHeightSmall &&
		`height: 40px;
		font-size: 15px;
	`}
    ${({ addButton }) =>
		addButton &&
		`height: 30px;
		font-size: 12px;
        margin-left: 5px;
	`}
	${({ ButtonLink }) =>
		ButtonLink &&
		`background: transparent;
		padding: 0;
		height: auto;
		width: auto;
		padding-right:5px;
		color: ${colors.BlueLight};
		font-size: 13px;
		line-height: 15px;
		text-transform: none;
		&:hover{
			color: ${colors.BlueBase};
		}
		span{
			font-size: 9px;
			position: relative;
			right: -6px;
		}
	`}
	${({ pointer }) => pointer && cursorControl(pointer)}
`;

export default Button;
