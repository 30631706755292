import { createGlobalStyle } from 'styled-components';
import './iconFont.scss';
import BoldWoff from '../fonts/inter-bold-webfont.woff2';
import LightBetaWoff from '../fonts/inter-light-beta-webfont.woff2';
import MediumWoff from '../fonts/inter-medium-webfont.woff2';
import RegularWoff from '../fonts/inter-regular-webfont.woff2';
import { colors } from '../../components/colors';
import { fontBold, fontLight, fontMedium, fontRegular } from './typography';

const GlobalStyle = createGlobalStyle`
::-webkit-scrollbar-track {
  -webkit-box-shadow: none;
  border-radius: 10px;
  background: none;
}


::-webkit-scrollbar {
  width: 4px;
  height: 4px;
  background: none;
}

.disableDiv {
      opacity:0.3
  }

::-webkit-scrollbar-thumb {
  border-radius: 14px;
  -webkit-box-shadow: none;
  background-color: ${colors.ScrollbarColor};
}
::-webkit-file-upload-button {
  cursor: pointer;
}
input[type=file] {
  cursor:pointer;
}
// .sb-show-main{
//   padding:0px;
// }
*:focus{
  outline: none;
}
@font-face {
    font-family: ${fontLight.familyName};
    src: local('☺'), local('inter-light-beta-webfont.woff2'),
    url(${LightBetaWoff}) format('woff2');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: ${fontMedium.familyName};;
    src: local('☺'), local('inter-medium-webfont.woff2'),
    url(${MediumWoff}) format('woff2');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: ${fontRegular.familyName};;
    src: local('☺'), local('inter-regular-webfont.woff2'),
    url(${RegularWoff}) format('woff2');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: ${fontBold.familyName};;
    src: local('☺'), local('inter-bold-webfont.woff2'),
    url(${BoldWoff}) format('woff2');
    font-weight: normal;
    font-style: normal;
}

::-webkit-input-placeholder {
  color: ${colors.PlaceholderColor};
  font-family: ${fontRegular.familyName};;
  font-size: 14px;
}
::-moz-placeholder {
  color: ${colors.PlaceholderColor};
  font-family: ${fontRegular.familyName};;
  font-size: 14px;
}
:-ms-input-placeholder {
  color: ${colors.PlaceholderColor};
  font-family: ${fontRegular.familyName};;
  font-size: 14px;
}
:-moz-placeholder {
  color: ${colors.PlaceholderColor};
  font-family: ${fontRegular.familyName};;
  font-size: 14px;
}

html {
    position: relative;
    min-height: 100%;
    line-height: 1.15;
    width: 100%;
    margin: 0;
    padding: 0;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    font-family: ${fontRegular.familyName};
    scrollbar-color: ${colors.ScrollbarColor};
	  scrollbar-width: thin;
     
  }

  body {
    min-height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
    letter-spacing: normal;
    line-height: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: ${fontRegular.familyName};
    scrollbar-color: ${colors.ScrollbarColor};
    scrollbar-width: thin;
    font-size: 14px;
    -webkit-print-color-adjust: exact !important;
    
  }


body.print-page
{
       -webkit-transform: scale(0.85);  /* Saf3.1+, Chrome */
             -moz-transform: scale(0.85);  /* FF3.5+ */
              -ms-transform: scale(0.85);  /* IE9 */
               -o-transform: scale(0.85);  /* Opera 10.5+ */
                  transform: scale(0.85);
        margin: -50px -73px 0;  /* big hack to reposition the page top the top and left of the viewer control */
}



  div,
  span,
  p,
  label,
  h1,
  h2,
  h3,
  h4,
  h5,
  input,
  select,
  textarea,
  button {
    margin: 0;
    font-family: ${fontRegular.familyName};
    overflow-wrap: break-word;
    font-weight: normal;
  }
button{
  cursor: pointer;
  text-decoration: none!important;
}
  * {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
  * {
    -webkit-overflow-scrolling: touch;
  }

  :after,
  :before {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }

  :focus {
    outline: 0 !important;
  }
  input[type="password"]{
    padding-top: 19px;
    font-size: 16px;
    font-family: ${fontBold.familyName};
    letter-spacing: .5px;
  }
  a{
    color: ${colors.BlueBase};
    text-decoration: underline;
    font-size: 14px;
    line-height: 22px;
    cursor:pointer;
    transition: ease-in all 0.4s;
    word-break: break-all;
    text-decoration: none!important;
    &:hover {
      text-decoration: none;
      color: ${colors.BlueLight};
    }
    button{
      cursor: pointer;
      text-decoration: none;
    }
  }
  b,
  strong {
    font-weight: 900;
  }

  @media (min-width: 1366px) {
    .container {
      max-width: 1280px;
    }
  }

  ::selection {
    color: ${colors.White};
    background: ${colors.Black};;
  }

  ::-moz-selection {
    color:  ${colors.White};
    background:  ${colors.Black};;
  }
  /* .optionListContainer {
      position: relative !important;
  } */

  .searchBox {
          margin-top: 10px !important;
  }

  .moodList :hover {
    
      /* border:1px solid black !important; */
      color: ${colors.BlueBase} !important;
      cursor: pointer;
      
    
  }
  .moodSelected {
    /* border:1px solid black !important; */
      color: ${colors.BlueBase} !important;
  }
  .jodit-container{width:100% !important;}
  .deleteIcon{
            background:transparent!important;
            padding:0px!important;
            color: ${colors.BlueBase};
            font-size:18px;
            margin-left:20px;
            &:hover{
                color: ${colors.Red};
            }
        }

  .DayPickerInputBlock{
        display: flex;
        align-items: center;
        position:relative;
        margin-bottom:24px;
        img{
            position:absolute;
            z-index:1;
            left:0;
        }
        h1{
            font-size: 17px;
            color: #0F255F;
            font-family: ${fontMedium.familyName} !important;
            margin-left:10px;
        }
        .DayPickerInput{
            width: 17px;
            z-index: 99;
            cursor: pointer;
             input{
                border:none;
                padding:0;
                font-size: 16px;
                color: #0F255F;
                font-family: ${fontMedium.familyName}!important;
                padding-left: 10px;
                opacity: 0;
                width: 20px;
                background: transparent;
                cursor: pointer;

            }
        }

    }
`;

export default GlobalStyle;
