import { lazy } from 'react';

import { Roles } from '../helpers/Constants';
import layoutProps from './route.params';

const Profile = lazy(() => import('../pages/Profile'));
const ClinicalNotes = lazy(() => import('../pages/ClinicalNotes'));
const Patients = lazy(() => import('../pages/PatientList'));
const ClinicManagement = lazy(() => import('../pages/ClinicManagement'));
const ClinicViewDetails = lazy(() => import('../pages/ClinicManagement/Controller/ViewController'));
const PatientManagement = lazy(() => import('../pages/PatientManagement'));
const PatientViewDetails = lazy(() =>
	import('../pages/PatientManagement/Controller/ViewController')
);
const CampList = lazy(() => import('../pages/Camps'));
const Questionnaire = lazy(() => import('../pages/Questionnaire'));
const ManageQuestionnaire = lazy(() => import('../pages/Questionnaire/manageQuestionnaire'));
const CampDetails = lazy(() => import('../pages/Camps/View/campDetails'));
const Survey = lazy(() => import('../pages/Camps/Survey/survey'));
const SurveyPreview = lazy(() => import('../pages/Camps/Survey/surveyPreview'));
const HealthCard = lazy(() => import('../pages/HealthCard'));
const HealthCardView = lazy(() => import('../pages/HealthCard/Controller/ViewController'));
const PatientDetails = lazy(() => import('../pages/PatientList/Components/PatientDetails'));
const Dashboard = lazy(() => import('../pages/IPSS/dashboard'));
const IPSSTest = lazy(() => import('../pages/IPSS/ipssQuestionnaire'));
const ILLNESSHISTORY = lazy(() => import('../pages/IPSS/illnessHistory'));

const Unauthorized = lazy(() => import('../pages/Unauthorized'));
const DietManagement = lazy(() => import('../pages/DietManagement'));

const PatientVault = lazy(() => import('../pages/PatientVault'));

export default [
	{
		exact: true,
		name: 'Dashboard',
		path: '/dashboard',
		component: Dashboard,
		visibleTo: [Roles.all],
	},
	{
		exact: true,
		name: 'IPSS Test',
		path: '/update-ipss-test',
		component: IPSSTest,
		visibleTo: [Roles.all],
	},
	{
		exact: true,
		name: 'Illness history',
		path: '/update-illness-history',
		component: ILLNESSHISTORY,
		visibleTo: [Roles.all],
	},
	{
		exact: true,
		name: 'Unauthorized',
		path: '/unauthorized',
		component: Unauthorized,
		visibleTo: [Roles.coach, Roles.doctor, Roles.weCareAdmin, Roles.clinicAdmin],
	},
	{
		exact: true,
		name: 'PatientList',
		path: '/patients',
		component: Patients,
		visibleTo: [Roles.coach, Roles.doctor, Roles.weCareAdmin, Roles.clinicAdmin],
	},

	{
		exact: true,
		name: 'Profile',
		path: '/profile',
		component: Profile,
		visibleTo: [Roles.coach, Roles.doctor, Roles.weCareAdmin, Roles.clinicAdmin],
	},
	{
		exact: true,
		name: 'ClinicalNotes',
		path: '/clinical-notes/:id',
		component: ClinicalNotes,
	},

	{
		exact: true,
		name: 'Patient detail',
		path: '/patient/:patientId',
		component: PatientDetails,
		visibleTo: [Roles.coach, Roles.doctor],
		layoutProps: layoutProps.patientManagement,
	},
	// {
	// 	exact: true,
	// 	name: 'PatientInvitation',
	// 	path: '/patient-invitation',
	// 	component: PatientInvitation,
	// 	visibleTo: [Roles.clinicAdmin, Roles.weCareAdmin],
	// },
	{
		exact: true,
		name: 'CampList',
		path: '/camp-list',
		component: CampList,
		visibleTo: [Roles.CM, Roles.FA],
	},
	{
		exact: true,
		name: 'Questionnaires',
		path: '/questionnaires',
		component: Questionnaire,
		visibleTo: [Roles.CM],
	},
	{
		exact: true,
		name: 'CampDetails',
		path: '/camp-details',
		component: CampDetails,
		visibleTo: [Roles.CM, Roles.FA],
	},
	{
		exact: true,
		name: 'Survey',
		path: '/survey',
		component: Survey,
		visibleTo: [Roles.CM, Roles.FA],
	},
	{
		exact: true,
		name: 'SurveyPreview',
		path: '/survey-preview',
		component: SurveyPreview,
		visibleTo: [Roles.CM, Roles.FA],
	},
	{
		exact: true,
		name: 'Questionnaires',
		path: '/manage-questionnaire',
		component: ManageQuestionnaire,
		visibleTo: [Roles.CM],
	},
	{
		exact: true,
		name: 'ClinicManagement',
		path: '/clinic-management',
		component: ClinicManagement,
		visibleTo: [Roles.weCareAdmin],
	},
	{
		exact: true,
		name: 'ClinicViewDetails',
		path: '/clinic-management/:id',
		component: ClinicViewDetails,
		visibleTo: [Roles.weCareAdmin],
	},
	{
		exact: true,
		name: 'DietManagement',
		path: '/diets',
		component: DietManagement,
		visibleTo: [Roles.coach, Roles.doctor, Roles.weCareAdmin],
	},
	{
		exact: true,
		name: 'PatientManagement',
		path: '/patient-management',
		component: PatientManagement,
		visibleTo: [Roles.weCareAdmin],
	},
	{
		exact: true,
		name: 'PatientViewDetails',
		path: '/patient-management/:id',
		component: PatientViewDetails,
		visibleTo: [Roles.weCareAdmin],
	},
	{
		exact: true,
		name: 'HealthCard',
		path: '/health-card',
		component: HealthCard,
		visibleTo: [Roles.weCareAdmin],
	},
	{
		exact: true,
		name: 'HealthCardView',
		path: '/health-card/:categoryID/:id',
		component: HealthCardView,
		visibleTo: [Roles.weCareAdmin],
	},
	{
		exact: true,
		name: 'Health',
		path: '/health',
		component: PatientVault,
		visibleTo: [Roles.patient],
	},
];
