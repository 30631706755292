import styled from 'styled-components';

import Image01 from '../../assets/images/Urology.png';

const HeaderLogo = styled.div`
    display: inline-block;
    width: 61px;
    height: 44px;
    display: flex;
    align-items: center;
    background: url(${Image01})
    no-repeat;
    /* background-size: cover; */
    background-size: contain;
    margin-right: 24px;
}
`;

export default HeaderLogo;
