import React from 'react';
import ReactDOM from 'react-dom';
// import * as Sentry from '@sentry/react';

import App from './App';
import * as serviceWorker from './serviceWorker';

import reportWebVitals from './reportWebVitals';
import { initializeFirebaseApp } from './service/firebaseService';

// Sentry.init({
// 	dsn: 'https://9ed43cd9d5874c8eab3cb543519a8e22@o488448.ingest.sentry.io/5854315',
// 	environment: 'production',
// 	autoSessionTracking: true,
// 	integrations: [new Integrations.BrowserTracing()],
// 	tracesSampleRate: 1.0,
// });

ReactDOM.render(<App />, document.getElementById('root'));

initializeFirebaseApp();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
reportWebVitals();
