import { LabReportList } from '../helpers/Constants';
import { getClinicDetails } from './clinicService';
import { Get } from './firebaseService';

export const getBasicProfile = async (collectionName, collectionId) => {
	const basicData = await Get(collectionName, collectionId).then((querySnapshot) => {
		if (querySnapshot.empty) return { data: null };
		return { data: querySnapshot.data() };
	});
	basicData.data.id = collectionId;
	return basicData;
};

const getHealthProfile = async (collectionName, collectionId) => {
	const healthProfile = [];
	await Get(collectionName, collectionId, true, 'HealthProfile').then((querySnapshot) => {
		querySnapshot.forEach((doc) => {
			const data = doc.data();
			// if (weightHistoryList.length > 0) {
			// 	data.weight = weightHistoryList[0].weight;
			// }
			healthProfile.push({ id: doc.id, data });
		});
	});
	return healthProfile;
};

export const getWeightHistory = async (collectionName, collectionId) => {
	let weightHistoryList = [];
	await Get(collectionName, collectionId, true, 'WeightHistory').then((querySnapshot) => {
		querySnapshot.forEach((doc) => {
			const data = doc.data();
			data.id = doc.id;
			weightHistoryList.push(data);
		});
	});
	weightHistoryList = await weightHistoryList.sort((a, b) => b.createdAt - a.createdAt);
	return weightHistoryList;
};

export const getVisitSummary = async (collectionName, collectionId) => {
	let visitSummaryList = [];
	await Get(collectionName, collectionId, true, 'VisitSummary').then((querySnapshot) => {
		querySnapshot.forEach((doc) => {
			visitSummaryList.push({ id: doc.id, data: doc.data() });
		});
	});
	visitSummaryList = await visitSummaryList.sort((a, b) => b.data.createdAt - a.data.createdAt);
	return visitSummaryList;
};

export const getVitalHistory = async (collectionName, collectionId) => {
	let vitalSummaryList = [];
	await Get(collectionName, collectionId, true, 'VitalSummary').then((querySnapshot) => {
		querySnapshot.forEach((doc) => {
			vitalSummaryList.push({ id: doc.id, data: doc.data() });
		});
	});

	vitalSummaryList = await vitalSummaryList.sort((a, b) => b.data.reportDate - a.data.reportDate);
	return {
		vitalsSummary: vitalSummaryList.length > 0 ? vitalSummaryList[0].data.vitals : [],
		vitalsList: vitalSummaryList,
	};
};

export const getVitalById = async (collectionName, collectionId) => {
	const vitalInfo = await Get(collectionName, collectionId).then((querySnapshot) => ({
		data: querySnapshot.data(),
	}));
	vitalInfo.data.id = collectionId;
	return vitalInfo;
};

export const getLabReportHistory = async (collectionName, collectionId) => {
	const labReportList = [];
	const labReportHistory = {};
	let reportList = [];
	const testSummary = [];
	await Get(collectionName, collectionId, true, 'LabReport').then((response) => {
		if (response && response.data) {
			console.log('DAT', response);
			response.data.forEach((doc) => {
				reportList.push(doc.data);
				labReportList.push({ id: doc.id, data: doc.data });
			});
		}
	});

	reportList = await reportList.sort((a, b) => b.date - a.date);
	LabReportList.forEach((test) => {
		const sum = reportList.find((rep) => rep.lookupKey === test.category);
		const his = reportList.filter((rep) => rep.lookupKey === test.category);
		if (sum) testSummary.push(sum);
		labReportHistory[test.category] = his;
	});

	return { labReport: labReportList, labReportSummary: testSummary, labReportHistory };
};

export const getClinicalDocList = async (collectionName, collectionId) => {
	const clinicalDocList = [];
	await Get(collectionName, collectionId, true, 'ClinicalDoc').then((response) => {
		// querySnapshot.forEach((doc) => {
		// 	clinicalDocList.push({ id: doc.id, data: doc.data() });
		// });
		if (response && response.data) {
			console.log('DAT', response);
			response.data.forEach((doc) => {
				clinicalDocList.push({ id: doc.id, data: doc.data });
			});
		}
	});
	return { clinicalDocs: clinicalDocList };
};

export const getProfile = async (collectionName, collectionId) => {
	const [clinicInfo, basicInfo, healthInfo, weightInfo] = await Promise.all([
		getClinicDetails(),
		// getBasicProfile(collectionName, collectionId),
		getHealthProfile(collectionName, collectionId),
		getWeightHistory(collectionName, collectionId),
	]);

	if (weightInfo.length > 0) {
		healthInfo[0].data.weight = weightInfo[0].weight;
	}

	return {
		...basicInfo,
		healthProfile: healthInfo.length > 0 ? healthInfo[0] : null,
		clinic: clinicInfo,
	};
};

export const getWeightHistorySummary = async (collectionName, collectionId) => {
	const [weightInfo, heightInfo] = await Promise.all([
		getWeightHistory(collectionName, collectionId),
		getHealthProfile(collectionName, collectionId),
	]);
	return {
		weightHistory: {
			weights: weightInfo,
			height: heightInfo.length > 0 ? heightInfo[0].data.height : 0,
			healthProfileId: heightInfo.length > 0 ? heightInfo[0].id : null,
		},
	};
};

export const getIdCard = async (collectionName, collectionId) => {
	const [clinicInfo, basicInfo, healthInfo] = await Promise.all([
		getClinicDetails(),
		// getBasicProfile(collectionName, collectionId),
		getHealthProfile(collectionName, collectionId),
	]);
	basicInfo.data.bloodGroup =
		healthInfo &&
		healthInfo.length > 0 &&
		healthInfo[0].data &&
		healthInfo[0].data.bloodGroup &&
		healthInfo[0].data.bloodGroup.length > 0
			? healthInfo[0].data.bloodGroup
			: '--';

	return {
		...basicInfo,
		clinic: clinicInfo,
	};
};

export const getLabReport = async (collectionName, collectionId) => {
	const [basicInfo, labReportInfo] = await Promise.all([
		// getBasicProfile(collectionName, collectionId),
		getLabReportHistory(collectionName, collectionId),
	]);
	return {
		...basicInfo,
		...labReportInfo,
	};
};
