import React from 'react';
import { Provider } from 'react-redux';

import AppRoutes from './Routes';
import SnackBarToast from './components/Snackbar/SnackBar';
import NetworkStatusBar from './components/Offline/NetworkStatusBar';

import { AppStore } from './store';

import GlobalStyle from './stories/assets/styles/globalStyle';
import Toast from './components/Toast';

function App() {
	return (
		<Provider store={AppStore}>
			<Toast />
			<GlobalStyle />
			<NetworkStatusBar />
			<SnackBarToast />
			<AppRoutes />
		</Provider>
	);
}

export default App;
