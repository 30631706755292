import { lazy } from 'react';

const Login = lazy(() => import('../pages/Login'));
const ForgotPassword = lazy(() => import('../pages/ForgotPassword'));
const ResetPassword = lazy(() => import('../pages/ChangePassword'));
const Profile = lazy(() => import('../pages/Login/basicInfo'));
const IllnessHistory = lazy(() => import('../pages/IPSS/illnessHistory'));
const IPSSTest = lazy(() => import('../pages/IPSS/ipssQuestionnaire'));

export default [
	{
		exact: true,
		name: 'Login',
		path: '/',
		component: Login,
	},
	{
		exact: true,
		name: 'Profile',
		path: '/profile',
		component: Profile,
	},
	{
		exact: true,
		name: 'IllnessHistory',
		path: '/illness-history',
		component: IllnessHistory,
	},
	{
		exact: true,
		name: 'IPSS Test',
		path: '/ipss-test',
		component: IPSSTest,
	},
	{
		exact: true,
		name: 'ForgotPassword',
		path: '/forgot-password',
		component: ForgotPassword,
	},
	{
		exact: true,
		name: 'ResetPassword',
		path: '/reset-password',
		component: ResetPassword,
	},
];
